import React from 'react';
import Drawer from '@mui/material/Drawer';
import Link from 'next/link';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { PAGE_CREATIVITY, PAGE_MAIN, PAGE_TOOLS } from '../../constants/pages';

const MobileButton = ({ href, text, onClick, disabled }) => (
  <Link href={href}>
    <Button
      sx={{ color: 'white', justifyContent: 'left' }}
      onClick={onClick}
      disabled={disabled}
      aria-disabled={undefined}
    >
      {text}
    </Button>
  </Link>
);

export const HeaderMobile = ({ t, currentPage, isDrawerOpen, onClick }) => (
  <Drawer
    anchor="left"
    open={isDrawerOpen}
    onClose={onClick}
    PaperProps={{
      sx: {
        background: '#2d364e',
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'
      }
    }}
  >
    <List>
      <ListItem>
        <MobileButton
          href={PAGE_MAIN}
          text={t('Main')}
          onClick={onClick}
          disabled={currentPage === PAGE_MAIN}
        />
      </ListItem>
      {/* <ListItem>
        <MobileButton
          href={PAGE_CREATIVITY}
          text={t('Creative')}
          onClick={onClick}
          disabled={currentPage === PAGE_CREATIVITY}
        />
      </ListItem> */}
      <ListItem>
        <MobileButton
          href={PAGE_TOOLS}
          text={t('Tools')}
          onClick={onClick}
          disabled={currentPage === PAGE_TOOLS}
        />
      </ListItem>
    </List>
  </Drawer>
);
