import React from 'react';
import { Box } from '@mui/material';
import { NextSeo } from 'next-seo';
import { StructuredBreadcrumb } from './Breadcrumb';

const Layout = ({ children, seoData, breadcrumbsData = [], host, locale }) => (
  <Box as="main">
    <StructuredBreadcrumb
      breadcrumbList={breadcrumbsData}
      separator="›"
      host={host}
      locale={locale}
    />
    <NextSeo {...seoData} />
    {children}
    <Box
      id="adlk-embed"
      sx={{
        alignItems: 'center',
        display: 'flex',
        margin: '20px',
        justifyContent: 'center'
      }}
    ></Box>
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `(function UTCoreInitialization() {
      if (window.UTInventoryCore) {
        new window.UTInventoryCore({
          type: "embed",
          host: 1677,
          content: false,
          container: "adlk-embed",
          width: 400,
          height: 225,
          playMode: "autoplay-visible",
          collapse: "none",
          infinity: true,
          infinityTimer: 1,
        });
        return;
      }
      setTimeout(UTCoreInitialization, 100);
    })();`
      }}
    ></script>
  </Box>
);

export { Layout };
