import React from 'react';
import Link from 'next/link';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { PAGE_CREATIVITY, PAGE_MAIN, PAGE_TOOLS } from '../../constants/pages';

const DesktopButton = ({ text, href, disabled }) => (
  <Link href={href} passHref>
    <Button
      disabled={disabled}
      aria-disabled={undefined}
      component="a"
      color="inherit"
      title={text}
      sx={{ width: 'max-content' }}
    >
      {text}
    </Button>
  </Link>
);

export const HeaderDesktop = ({ t, currentPage, onClick }) => (
  <Toolbar
    sx={{
      background: '#2d364e',
      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 3px 6px 0px',
      backgroundImage:
        'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))'
    }}
  >
    <Box
      sx={{
        flexGrow: 1,
        color: 'neutral.50',
        display: 'grid',
        maxWidth: '120px',
        marginRight: 'auto'
      }}
      itemScope
      itemType="http://schema.org/Organization"
    >
      <Link href={PAGE_MAIN} passHref>
        <Button
          component="a"
          itemProp="name"
          color="inherit"
          variant="text"
          title="AI NEXT"
        >
          AI NEXT
        </Button>
      </Link>
    </Box>
    <IconButton
      size="large"
      edge="start"
      color="inherit"
      aria-label="menu"
      sx={{ display: { xs: 'grid', md: 'none' } }}
      onClick={onClick}
    >
      <MenuIcon />
    </IconButton>
    {/* <List sx={{ display: { xs: 'none', md: 'flex' } }}>
      <ListItem sx={{ paddingY: '0' }}>
        <DesktopButton
          href={PAGE_CREATIVITY}
          text={t('Creative')}
          disabled={currentPage === PAGE_CREATIVITY}
        />
      </ListItem>
    </List> */}
    <List sx={{ display: { xs: 'none', md: 'flex' } }}>
      <ListItem sx={{ paddingY: '0' }}>
        <DesktopButton
          href={PAGE_TOOLS}
          text={t('Tools')}
          disabled={currentPage === PAGE_TOOLS}
        />
      </ListItem>
    </List>
  </Toolbar>
);
